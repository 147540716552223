.survey-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(250, 230, 255, 0.95);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out;
  transition: opacity 0.35s cubic-bezier(0.34, 1.56, 0.64, 1), 
              visibility 0.35s cubic-bezier(0.34, 1.56, 0.64, 1),
              transform 0.35s cubic-bezier(0.34, 1.56, 0.64, 1);
}

/* Create a higher z-index for the second survey to stack on top */
.survey-overlay.higher-survey {
  z-index: 2000 !important; /* Ensure it's higher than any other elements */
  animation: fadeIn 0.3s ease-out;
  position: fixed !important; /* Ensure it's properly positioned */
  pointer-events: auto !important; /* Make sure it can receive clicks */
  visibility: visible !important; /* Make sure it's visible */
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  display: flex !important; /* Force display */
  align-items: center !important;
  justify-content: center !important;
}

/* Fix for the empty screen issue */
.survey-overlay.higher-survey .survey-container {
  opacity: 1 !important;
  transform: translateY(0) !important;
  box-shadow: 0 0 30px rgba(128, 0, 255, 0.5),
              0 0 60px rgba(128, 0, 255, 0.3),
              0 0 90px rgba(255, 102, 255, 0.2);
  pointer-events: auto !important;
  visibility: visible !important;
  display: block !important;
}

.survey-container {
  background: rgba(255, 255, 255, 0.9);
  border-radius: clamp(2rem, 4vw, 3rem);
  padding: clamp(2rem, 5vh, 4rem);
  width: min(90%, 600px);
  box-shadow: 0 0 clamp(20px, 3vw, 30px) rgba(128, 0, 255, 0.5),
              0 0 clamp(40px, 5vw, 60px) rgba(128, 0, 255, 0.3),
              0 0 clamp(60px, 8vw, 90px) rgba(255, 102, 255, 0.2);
  position: relative;
  animation: slideUp 0.4s ease-out;
  transition: opacity 0.35s cubic-bezier(0.34, 1.56, 0.64, 1),
              transform 0.35s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.survey-container.fade-out {
  opacity: 0;
  transform: translateY(20px);
}

.survey-header {
  text-align: center;
  margin-bottom: clamp(2rem, 4vh, 3rem);
  color: #000;
  line-height: 1.5;
}

.survey-header h1 {
  font-size: clamp(1.2rem, 2.2vw, 1.6rem);
  font-weight: 400;
  margin-bottom: clamp(1rem, 2vh, 1.5rem);
}

.survey-header .highlight {
  color: #9933ff;
  font-weight: 600;
}

.survey-progress {
  height: 4px;
  background: rgba(153, 51, 255, 0.2);
  border-radius: 2px;
  margin-bottom: clamp(2rem, 4vh, 3rem);
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
  overflow: hidden;
}

.survey-progress-bar {
  height: 100%;
  background: #9933ff;
  border-radius: 2px;
  transition: width 0.3s ease;
  box-shadow: 0 0 10px rgba(153, 51, 255, 0.5);
}

.survey-question {
  font-size: clamp(1.5rem, 3vw, 2rem);
  font-weight: 800;
  color: #000;
  text-align: center;
  margin-bottom: clamp(2rem, 4vh, 3rem);
  line-height: 1.4;
  letter-spacing: -0.5px;
}

.survey-options {
  display: flex;
  flex-direction: column;
  gap: clamp(1rem, 2vh, 1.5rem);
}

.survey-option {
  font-size: clamp(1.1rem, 2vw, 1.4rem);
  font-weight: 600;
  color: #000;
  background: transparent;
  border: 2px solid #000;
  border-radius: clamp(1.5rem, 3vw, 2rem);
  padding: clamp(1rem, 2vh, 1.5rem);
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: center;
  box-shadow: 0 0 clamp(15px, 2.5vw, 25px) rgba(128, 0, 255, 0.2),
              inset 0 0 clamp(15px, 2.5vw, 25px) rgba(128, 0, 255, 0.1);
}

.survey-option:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 clamp(20px, 3vw, 30px) rgba(128, 0, 255, 0.4),
              0 0 clamp(40px, 5vw, 60px) rgba(128, 0, 255, 0.2),
              inset 0 0 clamp(20px, 3vw, 30px) rgba(128, 0, 255, 0.3);
}

/* iOS-specific adjustments */
@supports (-webkit-touch-callout: none) {
  .survey-option:hover {
    transform: translateY(-2px);
    box-shadow: 0 0 clamp(15px, 2.5vw, 25px) rgba(128, 0, 255, 0.2),
                inset 0 0 clamp(15px, 2.5vw, 25px) rgba(128, 0, 255, 0.1);
  }
}

.survey-option:active {
  transform: translateY(0);
}

.survey-close {
  position: absolute;
  top: clamp(1rem, 2vh, 1.5rem);
  right: clamp(1rem, 2vh, 1.5rem);
  font-size: clamp(2rem, 4vw, 2.5rem);
  color: #9933ff;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  width: clamp(2rem, 4vw, 2.5rem);
  height: clamp(2rem, 4vw, 2.5rem);
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  transition: all 0.2s ease;
  z-index: 10;
  opacity: 0.8;
}

.survey-close:hover {
  opacity: 1;
  transform: scale(1.1);
}

.survey-back {
  position: absolute;
  top: 50%;
  left: clamp(0.5rem, 1vh, 0.75rem);
  transform: translateY(-50%);
  font-size: clamp(2rem, 4vw, 2.5rem);
  color: #9933ff;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  width: clamp(2rem, 4vw, 2.5rem);
  height: clamp(2rem, 4vw, 2.5rem);
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  transition: all 0.2s ease;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
}

.survey-back.visible {
  opacity: 0.8;
  pointer-events: auto;
}

.survey-back.invisible {
  opacity: 0;
  pointer-events: none;
  visibility: visible; /* Makes sure it takes up space */
}

.survey-back:hover {
  opacity: 1;
  transform: translateY(-50%) scale(1.1);
}

.survey-input {
  font-size: clamp(1.1rem, 2vw, 1.4rem);
  font-weight: 400;
  color: #000;
  background: transparent;
  border: 2px solid #000;
  border-radius: clamp(1.5rem, 3vw, 2rem);
  padding: clamp(1rem, 2vh, 1.5rem);
  width: 80%;
  margin-right: auto;
  text-align: center;
  box-shadow: 0 0 clamp(15px, 2.5vw, 25px) rgba(128, 0, 255, 0.2),
              inset 0 0 clamp(15px, 2.5vw, 25px) rgba(128, 0, 255, 0.1);
  transition: all 0.2s ease;
}

.survey-input::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.survey-input:focus {
  outline: none;
  border-color: #9933ff;
  box-shadow: 0 0 clamp(20px, 3vw, 30px) rgba(128, 0, 255, 0.4),
              0 0 clamp(40px, 5vw, 60px) rgba(128, 0, 255, 0.2),
              inset 0 0 clamp(20px, 3vw, 30px) rgba(128, 0, 255, 0.3);
}

.survey-reorder-item {
  display: flex;
  align-items: center;
  padding: clamp(0.5rem, 1vh, 0.75rem) clamp(1rem, 2vw, 1.5rem);
  margin-bottom: clamp(0.5rem, 1vh, 0.75rem);
  background: white;
  border: 2px solid #000;
  border-radius: clamp(1rem, 2vw, 1.5rem);
  cursor: grab;
  transition: all 0.2s ease;
}

.survey-reorder-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 clamp(15px, 2.5vw, 25px) rgba(128, 0, 255, 0.2);
}

.survey-reorder-item.dragging {
  opacity: 0.5;
  cursor: grabbing;
}

.survey-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: clamp(0.5rem, 1vh, 0.75rem);
  justify-content: center;
}

.survey-checkbox-option {
  font-size: clamp(1rem, 1.8vw, 1.3rem);
  font-weight: 600;
  color: #000;
  background: transparent;
  border: 2px solid #000;
  border-radius: clamp(1.5rem, 3vw, 2rem);
  padding: clamp(0.75rem, 1.5vh, 1rem) clamp(1.5rem, 3vw, 2rem);
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: center;
  box-shadow: 0 0 clamp(15px, 2.5vw, 25px) rgba(128, 0, 255, 0.2),
              inset 0 0 clamp(15px, 2.5vw, 25px) rgba(128, 0, 255, 0.1);
}

.survey-checkbox-option.selected {
  background: #9933ff;
  color: white;
  border-color: #9933ff;
  box-shadow: 0 0 clamp(20px, 3vw, 30px) rgba(128, 0, 255, 0.4),
              0 0 clamp(40px, 5vw, 60px) rgba(128, 0, 255, 0.2),
              inset 0 0 clamp(20px, 3vw, 30px) rgba(128, 0, 255, 0.3);
}

.survey-next {
  position: absolute;
  top: 50%;
  right: clamp(0.5rem, 1vh, 0.75rem);
  transform: translateY(-50%);
  font-size: clamp(2rem, 4vw, 2.5rem);
  color: #9933ff;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  width: clamp(2rem, 4vw, 2.5rem);
  height: clamp(2rem, 4vw, 2.5rem);
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  transition: all 0.2s ease;
  z-index: 10;
  opacity: 0.8;
}

.survey-next:hover {
  opacity: 1;
  transform: translateY(-50%) scale(1.1);
}

.survey-next:disabled {
  opacity: 0.3;
  cursor: not-allowed;
  transform: translateY(-50%) scale(1);
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.survey-summary {
  text-align: center;
}

.mission-details {
  background: rgba(153, 51, 255, 0.1);
  border-radius: clamp(1rem, 2vw, 1.5rem);
  padding: clamp(1.5rem, 3vw, 2rem);
  margin: clamp(1.5rem, 3vh, 2rem) 0;
  max-height: clamp(250px, 40vh, 400px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; /* For smoother scrolling on iOS */
  scrollbar-width: thin;
  scrollbar-color: rgba(153, 51, 255, 0.4) rgba(153, 51, 255, 0.1);
  position: relative; /* Needed for absolute positioning of the scroll indicator */
}

.mission-details::-webkit-scrollbar {
  width: 8px;
}

.mission-details::-webkit-scrollbar-track {
  background: rgba(153, 51, 255, 0.1);
  border-radius: 4px;
}

.mission-details::-webkit-scrollbar-thumb {
  background-color: rgba(153, 51, 255, 0.4);
  border-radius: 4px;
}

/* Scroll indicator */
.scroll-indicator {
  position: absolute;
  top: -5px;
  left: 0;
  right: 0;
  background: linear-gradient(to top, rgba(153, 51, 255, 0.01), rgba(153, 51, 255, 0.15));
  padding: 8px 0 10px;
  text-align: center;
  font-size: 14px;
  color: #9933ff;
  font-weight: 600;
  pointer-events: none; /* Make sure it doesn't interfere with scrolling */
  opacity: 1;
  transition: opacity 0.3s ease;
  animation: pulseIndicator 1.5s infinite;
  z-index: 10;
  border-radius: 8px 8px 0 0;
}

.scroll-indicator.hidden {
  opacity: 0;
}

@keyframes pulseIndicator {
  0% {
    transform: translateY(0);
    opacity: 0.7;
  }
  50% {
    transform: translateY(3px);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 0.7;
  }
}

/* iOS-specific adjustments for the mission details */
@supports (-webkit-touch-callout: none) {
  .mission-details {
    /* Slightly smaller on iOS devices to account for notches and toolbars */
    max-height: clamp(220px, 35vh, 350px);
    /* Add bottom padding to ensure content isn't hidden behind home indicator */
    padding-bottom: clamp(2rem, 4vw, 2.5rem);
  }
  
  .scroll-indicator {
    /* Add extra bottom padding on iOS to account for home indicator */
    padding-bottom: 15px;
  }
}

/* For smaller screens, reduce the max height */
@media (max-height: 700px) {
  .mission-details {
    max-height: clamp(180px, 30vh, 300px);
  }
}

/* For very small screens */
@media (max-height: 550px) {
  .mission-details {
    max-height: clamp(150px, 25vh, 250px);
  }
}

.mission-details p {
  font-size: clamp(1.1rem, 2vw, 1.4rem);
  margin-bottom: clamp(0.75rem, 1.5vh, 1rem);
  line-height: 1.5;
}

.mission-details p:last-child {
  margin-bottom: 0;
}

.mission-details strong {
  color: #9933ff;
}

/* Mission Summary Page Buttons */
.mission-actions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.share-mission {
  transition: all 0.3s ease !important;
  box-shadow: 0 0 15px rgba(255, 20, 147, 0.4) !important;
}

.share-mission:hover {
  background-color: #e6006e !important;
  box-shadow: 0 0 20px rgba(255, 20, 147, 0.6) !important;
  transform: translateY(-3px) !important;
}

.find-match {
  transition: all 0.3s ease !important;
  box-shadow: 0 0 15px rgba(107, 33, 168, 0.4) !important;
}

.find-match:hover {
  background-color: #581c87 !important;
  box-shadow: 0 0 20px rgba(107, 33, 168, 0.6) !important;
  transform: translateY(-3px) !important;
}

.map-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
}

.map-navigation {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.map-navigation .survey-back,
.map-navigation .survey-next {
  position: static;
  transform: none;
  margin: 0;
  flex-shrink: 0;
}

.map-navigation .survey-input {
  flex-grow: 1;
}

.map {
  width: 100%;
  height: 300px;
  border-radius: 12px;
  overflow: visible;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
}

.map-container .survey-input {
  width: 100%;
  padding: 12px 16px;
  border: 2px solid #fae6ff;
  border-radius: 12px;
  font-size: 1rem;
  background: white;
  transition: all 0.3s ease;
}

.map-container .survey-input:focus {
  outline: none;
  border-color: #ff69b4;
  box-shadow: 0 0 0 3px rgba(255, 105, 180, 0.1);
}

.custom-marker {
  position: relative;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.marker-pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #ff69b4;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.marker-pin::after {
  content: '';
  width: 24px;
  height: 24px;
  margin: 3px 0 0 3px;
  background: #fff;
  position: absolute;
  border-radius: 50%;
}

.marker-label {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.trending-button {
  background-color: #9333ea;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  margin: 8px 0;
  transition: background-color 0.2s;
  width: 100%;
  max-width: 600px;
  margin: 8px auto;
  display: block;
}

.trending-button:hover {
  background-color: #7e22ce;
}

.trending-button:disabled {
  background-color: #a78bfa;
  cursor: not-allowed;
}

/* Learn More Button */
.learn-more-button {
  background: none;
  border: none;
  color: #9933ff;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  padding: 4px 8px;
  margin-left: 8px;
  transition: all 0.2s ease;
  font-size: 0.9em;
}

.learn-more-button:hover {
  color: #7700cc;
  transform: translateY(-1px);
}

/* Learn More Popup */
.learn-more-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(250, 230, 255, 0.95);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
  animation: fadeIn 0.3s ease-out;
}

.learn-more-container {
  background: rgba(255, 255, 255, 0.9);
  border-radius: clamp(2rem, 4vw, 3rem);
  padding: clamp(2rem, 5vh, 3.5rem);
  width: min(90%, 550px);
  box-shadow: 0 0 clamp(20px, 3vw, 30px) rgba(128, 0, 255, 0.5),
              0 0 clamp(40px, 5vw, 60px) rgba(128, 0, 255, 0.3),
              0 0 clamp(60px, 8vw, 90px) rgba(255, 102, 255, 0.2);
  position: relative;
  animation: slideUp 0.4s ease-out;
  text-align: center;
}

.learn-more-container h2 {
  font-size: clamp(1.4rem, 2.6vw, 1.8rem);
  color: #7700cc;
  margin-bottom: 20px;
}

.learn-more-feature h3 {
  font-size: clamp(1.1rem, 2vw, 1.3rem);
  margin-bottom: 8px;
  color: #9933ff;
}

.learn-more-feature p {
  font-size: clamp(0.9rem, 1.8vw, 1.1rem);
  line-height: 1.5;
  color: #333;
}

.learn-more-close {
  position: absolute;
  top: clamp(1rem, 2vh, 1.5rem);
  right: clamp(1rem, 2vh, 1.5rem);
  font-size: clamp(2rem, 4vw, 2.5rem);
  color: #9933ff;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  width: clamp(2rem, 4vw, 2.5rem);
  height: clamp(2rem, 4vw, 2.5rem);
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  transition: all 0.2s ease;
  z-index: 10;
  opacity: 0.8;
}

.learn-more-close:hover {
  opacity: 1;
  transform: scale(1.1);
}

.learn-more-try {
  font-size: clamp(1.1rem, 2vw, 1.4rem);
  font-weight: 600;
  color: white;
  background: #9933ff;
  border: none;
  border-radius: clamp(1.5rem, 3vw, 2rem);
  padding: clamp(0.8rem, 1.6vh, 1.2rem) clamp(2rem, 4vw, 3rem);
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 0 clamp(15px, 2.5vw, 25px) rgba(128, 0, 255, 0.3);
  margin-top: 16px;
}

.learn-more-try:hover {
  transform: translateY(-2px);
  background: #7700cc;
  box-shadow: 0 0 clamp(20px, 3vw, 30px) rgba(128, 0, 255, 0.5);
}

/* Learn More Carousel */
.learn-more-carousel {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  min-height: 120px;
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
}

.carousel-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: clamp(2rem, 4vw, 2.5rem);
  color: #9933ff;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  width: clamp(2rem, 4vw, 2.5rem);
  height: clamp(2rem, 4vw, 2.5rem);
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  transition: all 0.2s ease;
  z-index: 10;
  opacity: 0.8;
}

.carousel-nav.prev {
  left: -30px;
}

.carousel-nav.next {
  right: -30px;
}

.carousel-nav:hover {
  opacity: 1;
  transform: translateY(-50%) scale(1.1);
}

.learn-more-feature {
  animation: cardFadeIn 0.4s ease-out;
  width: 80%;
  max-width: 320px;
  margin: 0 auto;
  text-align: center;
  background: rgba(153, 51, 255, 0.05);
  border-radius: 16px;
  border-left: 3px solid #9933ff;
  border-right: 3px solid #9933ff;
  padding: 16px 20px;
  box-shadow: 0 4px 15px rgba(153, 51, 255, 0.1);
}

.carousel-indicators {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 20px;
}

.carousel-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(153, 51, 255, 0.3);
  cursor: pointer;
  transition: all 0.2s ease;
}

.carousel-dot.active {
  background-color: #9933ff;
  transform: scale(1.2);
}

@keyframes cardFadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* First Question Styling */
.first-question-options {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: clamp(1rem, 2vh, 1.5rem);
  max-width: 85%;
  margin: 0 auto;
}

.first-question-options .survey-option.selected {
  background: rgba(153, 51, 255, 0.1);
  border-color: #9933ff;
  box-shadow: 0 0 clamp(20px, 3vw, 30px) rgba(128, 0, 255, 0.4),
              0 0 clamp(40px, 5vw, 60px) rgba(128, 0, 255, 0.2),
              inset 0 0 clamp(20px, 3vw, 30px) rgba(128, 0, 255, 0.3);
}

/* Mission Share Link Styles */
.mission-share-link {
  margin-top: 16px;
  width: 100%;
}

.mission-share-link p {
  font-size: 14px;
  margin-bottom: 8px;
  color: #666;
}

.share-url-container {
  display: flex;
  width: 100%;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.share-url-input {
  flex-grow: 1;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-right: none;
  border-radius: 8px 0 0 8px;
  font-size: 12px;
  color: #333;
  background: white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.copy-url-button {
  background-color: #9933ff;
  color: white;
  border: none;
  padding: 10px 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  white-space: nowrap;
}

.copy-url-button:hover {
  background-color: #7700cc;
}

/* Disabled button state */
.survey-option:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background-color: #ffc0d8 !important;
  border-color: #ffc0d8 !important;
}

/* Class to fix the survey overlay that disappears */
.gender-survey {
  background: rgba(250, 230, 255, 0.95) !important;
  backdrop-filter: blur(10px) !important;
}

/* Animation for smooth transition between surveys */
.slide-in-top {
  animation: slideInTop 0.4s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
}

@keyframes slideInTop {
  from {
    opacity: 0;
    transform: translateY(-15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Animation for sliding out */
.slide-out-top {
  animation: slideOutTop 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) forwards;
}

@keyframes slideOutTop {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-15px);
  }
}

/* Cross-fade transition container */
.cross-fade-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
  pointer-events: none;
} 